import { Col, Row, Flex } from "antd";
import {
  useIntl,
  BasicButton,
  BasicSortBy,
  DEACTIVATED,
  useDevice,
  NotificationContext,
  useScreenOptions,
} from "@datwyler/mfe-shared-components";
import { useContext, useState } from "react";
import DeactivateDeviceDialog from "../../Dialogs/DeactivateDeviceDialog";
import Filter from "./Filter";

const FiltersAndControl = (props) => {
  const {
    filters = {},
    order,
    setOrder,
    locations,
    models,
    setPageNumber,
    setFilters,
    selected,
    setSelected,
    tenantId,
  } = props;
  const { openNotification }: any = useContext(NotificationContext);
  const { isMobileScreen } = useScreenOptions();
  const [isDeactivateDeviceDialogOpen, setIsDeactivateDeviceDialogOpen] =
    useState(false);
  const intl = useIntl();
  const { batchUpdate } = useDevice();

  // For sorting
  const selectOptions = [
    {
      label: intl.formatMessage({ id: "type" }),
      value: "type",
    },
  ];

  const handleConfirmDelete = async () => {
    const allSubmitValues = [];
    selected.forEach((device) => {
      const submitValues = {
        tenant: { id: tenantId },
        id: device.id,
        name: device.name,
        status: DEACTIVATED,
      };

      allSubmitValues.push(submitValues);
    });

    const allResponses = await batchUpdate(allSubmitValues);

    allResponses.map((response) => {
      if (response?.data?.updateDevice?.device) {
        openNotification({
          type: "success",
          description:
            intl.formatMessage({ id: "success_del" }) +
            ` ${intl.formatMessage({ id: "device" })}: ` +
            response.data.updateDevice.device.name,
          placement: "topRight",
        });
      } else {
        openNotification({
          type: "error",
          description: intl.formatMessage({ id: "error" }),
          placement: "topRight",
        });
      }
    });

    setIsDeactivateDeviceDialogOpen(false);
    setSelected([]);
  };

  return (
    <Row>
      <Col span={24} style={{ display: "flex", alignItems: "center" }}>
        <DeactivateDeviceDialog
          isOpen={isDeactivateDeviceDialogOpen}
          handleOk={handleConfirmDelete}
          handleCancel={() => {
            setIsDeactivateDeviceDialogOpen(false);
          }}
        />
        {!isMobileScreen && (
          <Filter
            filters={filters}
            setPageNumber={setPageNumber}
            setFilters={setFilters}
            locations={locations}
            models={models}
          />
        )}

        <BasicSortBy
          options={selectOptions}
          value={order}
          onChange={setOrder}
          justify={"start"}
        />
        {selected?.length > 0 && (
          <Flex style={{ width: "100%", justifyContent: "end" }}>
            <BasicButton
              danger
              type="primary"
              onClick={() => {
                setIsDeactivateDeviceDialogOpen(true);
              }}
              style={{ flexGrow: isMobileScreen ? 1 : 0 }}
            >
              {intl.formatMessage({ id: "delete" })}
            </BasicButton>
          </Flex>
        )}
      </Col>
    </Row>
  );
};

export default FiltersAndControl;
