import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import BreadcrumbsDevice from "../component/Common/Breadcrumbs/BreadcrumbsDevice";
import {
  useLoadingGif,
  TenantIdContext,
  ACTIVE,
  exportToExcel,
  useIntl,
  useScreenOptions,
  useDevice,
  useLocation,
  useModel,
  withPermission,
} from "@datwyler/mfe-shared-components";
import Header from "../component/Device/Header";
import FiltersAndControl from "../component/Device/FiltersAndControl";
import DeviceTable from "../component/Device/DeviceTable";
import { getLocationFromSiteId, getTelemetryData } from "../utils";
import { filterDefaults } from "../utils/constants";

const getSort = (order) => {
  let sort = [];

  if (order) {
    switch (order) {
      case "type":
        sort.push(`type,asc`);
        break;

      default:
        sort.push(`type,asc`);
    }
  }

  sort.push(`name,asc`);
  return sort;
};

const getFilters = (filters) => {
  const filtersToSend = [`status:${ACTIVE}`];

  if (filters.sites.length > 0) {
    let filterSites = "";
    filters.sites.forEach((siteId, index) => {
      if (index === 0) filterSites = filterSites + `siteId:` + siteId;
      else filterSites = filterSites + `|siteId:` + siteId;
    });
    filtersToSend.push(filterSites);
  }

  if (filters.deviceTypes.length > 0) {
    let filterDevices = "";
    filters.deviceTypes.forEach((deviceType, index) => {
      if (index === 0) filterDevices = filterDevices + `type:` + deviceType;
      else filterDevices = filterDevices + `|type:` + deviceType;
    });
    filtersToSend.push(filterDevices);
  }

  return filtersToSend;
};

const Device = () => {
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const { isMobileScreen } = useScreenOptions();
  const { tenantId }: any = useContext(TenantIdContext);
  const intl = useIntl();
  const {
    isFetchLoading: isFetchLocationLoading,
    fetchLocationData,
    fetchLocations,
  } = useLocation();
  const { isFetchModelsLoading, fetchModelsData, fetchModels } = useModel();
  const {
    isFetchLoading: isFetchDeviceLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();
  const {
    isFetchLoading: isFetchDeviceForExportLoading,
    fetchDeviceData: fetchDeviceDataForExport,
    fetchDevices: fetchDevicesForExport,
  } = useDevice();

  const [filters, setFilters] = useState(filterDefaults);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("type");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (tenantId) {
      fetchLocations({
        variables: { tenantId: tenantId },
      });

      fetchModels();
    }
  }, [tenantId]);

  useEffect(() => {
    refetchDevices();
  }, [tenantId, pageNumber, rowsPerPage, order, filters]);

  useEffect(() => {
    setIsLoading(
      isFetchDeviceLoading ||
        isFetchLocationLoading ||
        isFetchModelsLoading ||
        isFetchDeviceForExportLoading ||
        false
    );
  }, [
    isFetchDeviceLoading,
    isFetchLocationLoading,
    isFetchModelsLoading,
    isFetchDeviceForExportLoading,
  ]);

  useEffect(() => {
    if (fetchDeviceDataForExport?.devices?.devices) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_devices" })
      );
    }
  }, [fetchDeviceDataForExport]);

  const refetchDevices = () => {
    const filtersToSend = getFilters(filters);
    const sort = getSort(order);

    if (tenantId) {
      fetchDevices({
        variables: {
          tenantId: tenantId,
          filter: filtersToSend,
          sort: sort,
          page: { number: pageNumber - 1, size: rowsPerPage }, //page in api start from 0
        },
      });
    }
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters(filters);
    const sort = getSort(order);

    fetchDevicesForExport({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: sort,
        page: { number: 0, size: 999999 },
      },
    });
  };

  const getExcelData = () => {
    const excelData = fetchDeviceDataForExport?.devices?.devices.map((data) => {
      const row = {};

      const status = getTelemetryData(data?.telemetry, "status");
      const location = getLocationFromSiteId(
        fetchLocationData?.locations,
        data.site?.id
      );

      row[intl.formatMessage({ id: "device_name" })] = data.name || "-";
      row[intl.formatMessage({ id: "type" })] = data.type
        ? intl.formatMessage({ id: data.type })
        : "-";
      row[intl.formatMessage({ id: "device_id" })] = data.id || "-";
      row[intl.formatMessage({ id: "location" })] = location?.name || "-";
      row[intl.formatMessage({ id: "site" })] = data.site?.name || "-";
      row[intl.formatMessage({ id: "status" })] = status
        ? intl.formatMessage({ id: status.toLowerCase() })
        : "-";

      return row;
    });
    return excelData;
  };

  return (
    <Row style={{ marginBottom: "24px", height: "300px" }} gutter={[0, 24]}>
      <LoadingGif />
      {!isMobileScreen && (
        <Col span={24}>
          <BreadcrumbsDevice />
        </Col>
      )}
      <Col span={24}>
        <Card>
          <Header
            fetchDataForExcel={fetchDataForExcel}
            locations={fetchLocationData?.locations}
            models={fetchModelsData?.models}
            filters={filters}
            setFilters={setFilters}
            setPageNumber={setPageNumber}
          />

          <FiltersAndControl
            order={order}
            setOrder={setOrder}
            locations={fetchLocationData?.locations}
            models={fetchModelsData?.models}
            setPageNumber={setPageNumber}
            filters={filters}
            setFilters={setFilters}
            selected={selected}
            setSelected={setSelected}
            tenantId={tenantId}
          />
          <DeviceTable
            data={fetchDeviceData?.devices}
            locations={fetchLocationData?.locations}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPageNumber={setPageNumber}
            selected={selected}
            setSelected={setSelected}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default withPermission("/device-monitoring")(Device);
