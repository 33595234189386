import { Col, Row, Typography } from "antd";
import {
  DownloadIcon,
  AddIcon,
  useIntl,
  useScreenOptions,
  BasicButton,
} from "@datwyler/mfe-shared-components";
import AddDeviceDialog from "../../Dialogs/AddDeviceDialog";
import { useState } from "react";
import Filter from "../FiltersAndControl/Filter";

const { Title } = Typography;

const Header = (props) => {
  const {
    fetchDataForExcel,
    locations,
    models,
    filters,
    setFilters,
    setPageNumber,
  } = props;
  const intl = useIntl();
  const { isMobileScreen } = useScreenOptions();
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false);

  return (
    <Row style={{ marginBottom: isMobileScreen ? "16px" : "24px" }}>
      <AddDeviceDialog
        isAddDeviceDialogOpen={isAddDeviceDialogOpen}
        handleCloseAddDeviceDialog={() => {
          setIsAddDeviceDialogOpen(false);
        }}
        locations={locations}
        models={models}
      />
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <Title level={2} style={{ marginTop: "0px" }}>
          {intl.formatMessage({ id: "menu_device" })}
        </Title>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        style={{
          justifyContent: isMobileScreen ? "left" : "right",
          marginTop: isMobileScreen ? "20px" : "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {isMobileScreen && (
          <Filter
            filters={filters}
            setPageNumber={setPageNumber}
            setFilters={setFilters}
            locations={locations}
            models={models}
          />
        )}
        <BasicButton
          style={{
            marginRight: "16px",
            marginLeft: isMobileScreen ? "0px" : "16px",
          }}
          onClick={fetchDataForExcel}
          icon={<DownloadIcon width={"24px"} height={"24px"} />}
        >
          {isMobileScreen ? "" : intl.formatMessage({ id: "export" })}
        </BasicButton>

        <BasicButton
          type="primary"
          icon={<AddIcon width={"20px"} height={"20px"} />}
          onClick={() => {
            setIsAddDeviceDialogOpen(!isAddDeviceDialogOpen);
          }}
          style={{ flexGrow: isMobileScreen ? 1 : 0 }}
        >
          {isMobileScreen
            ? intl.formatMessage({ id: "add" })
            : intl.formatMessage({ id: "add_device" })}
        </BasicButton>
      </Col>
    </Row>
  );
};

export default Header;
