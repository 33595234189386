import { Typography } from "antd";
import {
  BasicModal,
  buttonStyle,
  useIntl,
} from "@datwyler/mfe-shared-components";
import { textStyle } from "../../../utils/styles";

const { Text } = Typography;

const DeactivateDeviceDialog = (props) => {
  const { handleOk, handleCancel, isOpen } = props;
  const intl = useIntl();

  return (
    <BasicModal
      title={intl.formatMessage({ id: "deactivate_device" })}
      isOpen={isOpen}
      handleCancel={handleCancel}
      handleOk={handleOk}
      cancelButtonProps={{
        style: buttonStyle,
      }}
      okText={intl.formatMessage({ id: "deactivate_device" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      okButtonProps={{
        type: "primary",
        danger: true,
        style: {
          ...buttonStyle,
          fontSize: "16px",
        },
      }}
    >
      <Text style={textStyle}>
        {intl.formatMessage({ id: "confirm_deactivate" })}
      </Text>
    </BasicModal>
  );
};

export default DeactivateDeviceDialog;
