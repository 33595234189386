export const textStyle = {
  fontSize: "16px",
  fontWeight: 400,
};

export const maxCharStyle = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "#83878E",
};
