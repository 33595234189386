import {
  BasicBreadcrumbs,
  useIntl,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { Link, useLocation } from "react-router-dom";

const BreadcrumbsDevice = () => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <BasicBreadcrumbs
      link={Link}
      state={_.get(location, "state", null)}
      items={[
        {
          title: intl.formatMessage({ id: "menu_home" }),
          href: "/home",
        },
        {
          title: intl.formatMessage({ id: "menu_device" }),
        },
      ]}
    />
  );
};

export default BreadcrumbsDevice;
