import { Flex, Row, Col } from "antd";
import { useState } from "react";
import { getDeviceTypeOptions } from "../../../../utils";
import {
  BasicSelectMultiple,
  useIntl,
  BasicButton,
  BasicFilter,
  useScreenOptions,
  getSiteOptions,
} from "@datwyler/mfe-shared-components";
import { filterDefaults } from "../../../../utils/constants";

const Filter = (props) => {
  const { filters, setPageNumber, setFilters, locations, models } = props;
  let interimFilters = { ...filters };
  const [isShowFiltersDialog, setIsShowFiltersDialog] = useState(false);
  const intl = useIntl();
  const { isMobileScreen } = useScreenOptions();

  const handleChangeSite = (values) => {
    interimFilters.sites = values;
  };

  const handleChangeType = (values) => {
    interimFilters.deviceTypes = values;
  };

  const handleReset = () => {
    setPageNumber(1);
    setFilters(filterDefaults);
    interimFilters = { ...filterDefaults };
  };

  const handleApply = () => {
    setPageNumber(1);
    setFilters(interimFilters);
    setIsShowFiltersDialog(false);
  };

  const FilterContent = () => (
    <Flex style={{ width: "360px" }}>
      <Row style={{ width: "100%" }} gutter={[0, 16]}>
        <Col span={24}>
          <BasicSelectMultiple
            defaultValue={interimFilters.sites}
            options={getSiteOptions(locations)}
            placeholder={intl.formatMessage({ id: "select_site" })}
            onChange={handleChangeSite}
          />
        </Col>
        <Col span={24}>
          <BasicSelectMultiple
            defaultValue={interimFilters.deviceTypes}
            options={getDeviceTypeOptions(models, intl)}
            placeholder={intl.formatMessage({ id: "select_device_type" })}
            onChange={handleChangeType}
          />
        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <BasicButton onClick={handleReset} style={{ minWidth: "120px" }}>
            {intl.formatMessage({ id: "reset" })}
          </BasicButton>
          <BasicButton
            type={"primary"}
            style={{ marginLeft: "16px", minWidth: "120px" }}
            onClick={handleApply}
          >
            {intl.formatMessage({ id: "apply" })}
          </BasicButton>
        </Col>
      </Row>
    </Flex>
  );

  return (
    <BasicFilter
      content={<FilterContent />}
      open={isShowFiltersDialog}
      setOpen={setIsShowFiltersDialog}
      isHideLabel={isMobileScreen ? true : false}
      overlayStyle={{ width: "auto" }}
    />
  );
};

export default Filter;
