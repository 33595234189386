import { Col, Form, Input, Row, Typography } from "antd";
import {
  BasicModal,
  buttonStyle,
  useIntl,
  SingleSelect,
  TenantIdContext,
  ACTIVE,
  NotificationContext,
  useDevice,
  useLoadingGif,
  useScreenOptions,
  getSiteOptions,
} from "@datwyler/mfe-shared-components";
import { useContext, useEffect, useMemo, useState } from "react";
import { getDeviceTypeOptions } from "../../../utils";
import { maxCharStyle } from "../../../utils/styles";

const { Text } = Typography;

const getModelOptions = (models, selectedDeviceType) => {
  const availModels =
    models?.filter((model) => model.deviceType === selectedDeviceType) || [];

  if (availModels.length < 1) return [];

  return availModels.map((model) => ({
    label: model.name,
    value: model.id,
  }));
};

const AddDeviceDialog = (props) => {
  const {
    isAddDeviceDialogOpen,
    handleCloseAddDeviceDialog,
    locations,
    models,
  } = props;
  const { openNotification }: any = useContext(NotificationContext);
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const intl = useIntl();
  const [form] = Form.useForm();
  const [countName, setCountName] = useState(0);
  const { tenantId }: any = useContext(TenantIdContext);
  const {
    addDevice,
    addDeviceResponseData,
    isAddDeviceLoading,
    resetAddDeviceData,
  } = useDevice();
  const { isMobileScreen } = useScreenOptions();

  const selectedDeviceType = Form.useWatch("type", form);

  useEffect(() => {
    setIsLoading(isAddDeviceLoading || false);
  }, [isAddDeviceLoading]);

  useEffect(() => {
    if (addDeviceResponseData?.addDevice?.device) {
      resetAddDeviceData();
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "add_device_success" }),
        placement: "topRight",
      });
      handleCloseAddDeviceDialog();
    }
  }, [addDeviceResponseData]);

  const siteOptions = useMemo(() => {
    return getSiteOptions(locations);
  }, [locations]);

  const deviceTypeOptions = useMemo(() => {
    return getDeviceTypeOptions(models, intl);
  }, [models]);

  const modelOptions = useMemo(() => {
    form?.setFieldValue("model", undefined);
    return getModelOptions(models, selectedDeviceType);
  }, [selectedDeviceType, models, form]);

  const handleSubmit = (values) => {
    const submitValues = transformBeforeSubmit(values);
    // call api to add device
    addDevice({
      variables: { input: submitValues },
    });
  };

  const transformBeforeSubmit = (values) => {
    const submitValues = JSON.parse(JSON.stringify(values));
    submitValues.tenant = { id: tenantId };
    submitValues.status = ACTIVE;
    return submitValues;
  };

  return (
    <BasicModal
      title={intl.formatMessage({ id: "add_device" })}
      isOpen={isAddDeviceDialogOpen}
      handleCancel={handleCloseAddDeviceDialog}
      handleOk={() => {}}
      okText={intl.formatMessage({ id: "add_device" })}
      cancelText={intl.formatMessage({ id: "cancel" })}
      cancelButtonProps={{
        style: {
          ...buttonStyle,
          width: isMobileScreen ? "49%" : "unset",
        },
      }}
      okButtonProps={{
        type: "primary",
        style: {
          ...buttonStyle,
          fontSize: "16px",
          width: isMobileScreen ? "49%" : "unset",
        },
        htmlType: "submit",
        autoFocus: true,
      }}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="addDeviceForm"
          clearOnDestroy
          onFinish={(values) => handleSubmit(values)}
        >
          {dom}
        </Form>
      )}
    >
      <LoadingGif />
      <Form.Item
        label={intl.formatMessage({ id: "site" })}
        name={["site", "id"]}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <SingleSelect
          options={siteOptions}
          style={{ width: "100%", height: "57.6px" }}
          popupMatchSelectWidth={"100%"}
          placeholder={intl.formatMessage({ id: "select_site" })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: "device_type" })}
        name="type"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <SingleSelect
          options={deviceTypeOptions}
          style={{ width: "100%", height: "57.6px" }}
          popupMatchSelectWidth={"100%"}
          placeholder={intl.formatMessage({ id: "select_type_of_device" })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: "model" })}
        name={["model", "id"]}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <SingleSelect
          options={modelOptions}
          style={{ width: "100%", height: "57.6px" }}
          popupMatchSelectWidth={"100%"}
          placeholder={intl.formatMessage({ id: "select_model" })}
          error={form.getFieldError("model")}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: "device_name" })}
        name="name"
        style={{ marginBottom: 0 }}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required_msg" }),
          },
        ]}
      >
        <Input
          size="large"
          maxLength={20}
          placeholder={intl.formatMessage({ id: "enter_device_name" })}
          onChange={(e) => setCountName(e.target.value?.length || 0)}
        />
      </Form.Item>
      <Row>
        <Col span={12} style={{ textAlign: "left" }}>
          <Text style={maxCharStyle}>
            {intl.formatMessage({ id: "max_char" })}: 20
          </Text>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Text style={maxCharStyle}>{countName}/20</Text>
        </Col>
      </Row>
    </BasicModal>
  );
};

export default AddDeviceDialog;
