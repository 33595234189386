export const getLocationFromSiteId = (locations: any, siteId: String) => {
  if (!locations) return;
  return locations.find((loc) =>
    loc?.sites?.some((site) => site.id === siteId)
  );
};

export const getTelemetryData = (telemetry, key) => {
  const data = telemetry?.find((data) => data.name === key);
  return data?.value;
};

export const copyToClipboard = (device, openNotification, intl) => {
  try {
    if (navigator?.clipboard) {
      // this uses https but Currently SIT is not secured
      navigator.clipboard.writeText(device.id);
    } else {
      // Unsecured, document.execCommand is deprecated
      unsecuredCopy(device.id);
    }
  } catch (exception) {
    openNotification({
      type: "error",
      description: intl.formatMessage({ id: "error" }),
      placement: "topRight",
    });
    return;
  }

  openNotification({
    type: "success",
    description: intl.formatMessage({ id: "device_id_copy_success" }),
    placement: "topRight",
  });
};

const unsecuredCopy = (text) => {
  var textarea = document.createElement("textarea");
  textarea.textContent = text;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand("copy");
  selection.removeAllRanges();

  document.body.removeChild(textarea);
};

export const getDeviceTypeOptions = (models, intl) => {
  const deviceTypeOptions = [];

  if (models?.length > 0) {
    const checkAddedDeviceTypes = [];

    models.forEach((model) => {
      if (!checkAddedDeviceTypes.includes(model.deviceType)) {
        deviceTypeOptions.push({
          label: intl.formatMessage({ id: model.deviceType }),
          value: model.deviceType,
        });
        checkAddedDeviceTypes.push(model.deviceType);
      }
    });
  }

  return deviceTypeOptions;
};
