import { Form, Input } from "antd";
import {
  BasicModal,
  buttonStyle,
  useIntl,
} from "@datwyler/mfe-shared-components";

const UpdateDeviceDialog = (props) => {
  const { handleOk, handleCancel, isOpen, selectedDevice } = props;
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    handleOk(values);
  };

  if (form)
    return (
      <BasicModal
        title={intl.formatMessage({ id: "update_device" })}
        isOpen={isOpen}
        handleCancel={handleCancel}
        handleOk={() => {}}
        cancelButtonProps={{
          style: buttonStyle,
        }}
        okText={intl.formatMessage({ id: "save_changes" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
        okButtonProps={{
          type: "primary",
          style: {
            ...buttonStyle,
            fontSize: "16px",
          },
          htmlType: "submit",
          autoFocus: true,
        }}
        forceRender
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="updateDeviceForm"
            clearOnDestroy
            onFinish={(values) => handleSubmit(values)}
            initialValues={{
              name: selectedDevice.name,
            }}
          >
            {dom}
          </Form>
        )}
      >
        <Form.Item
          label={intl.formatMessage({ id: "device_name" })}
          name="name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "required_msg" }),
            },
          ]}
        >
          <Input size="large" maxLength={20} />
        </Form.Item>
      </BasicModal>
    );
};

export default UpdateDeviceDialog;
