import {
  BasicBreadcrumbs,
  useIntl,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { Link, useLocation } from "react-router-dom";
const BreadcrumbsDeviceDetails = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const { device } = props;

  return (
    <BasicBreadcrumbs
      link={Link}
      state={_.get(location, "state", null)}
      items={[
        {
          title: intl.formatMessage({ id: "menu_home" }),
          href: "/home",
        },
        {
          title: intl.formatMessage({ id: "menu_device" }),
          href: "/device-monitoring",
        },
        {
          title: device?.name,
        },
      ]}
    />
  );
};

export default BreadcrumbsDeviceDetails;
