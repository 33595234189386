import { Table, Form, Switch, InputNumber, Input } from "antd";

const SetParametersTable = (props) => {
  const { data } = props;

  const columns = [
    {
      dataIndex: "name",
      ellipsis: true,
    },
    {
      dataIndex: "type",
      ellipsis: true,
      render: (_, record) => getInput(record),
    },
  ];

  const getInput = (data) => {
    switch (data.type) {
      case "BOOLEAN":
        return (
          <Form.Item
            name={data.name}
            style={{
              justifyContent: "right",
              display: "flex",
              marginBottom: 0,
            }}
          >
            <Switch />
          </Form.Item>
        );
      case "STRING":
        return (
          <Form.Item
            name={data.name}
            style={{
              justifyContent: "right",
              display: "flex",
              marginBottom: 0,
            }}
          >
            <Input
              style={{ width: 87.78, height: 29.9, background: "transparent" }}
            />
          </Form.Item>
        );
      case "INTEGER":
      case "DOUBLE":
        return (
          <Form.Item
            name={data.name}
            style={{
              justifyContent: "right",
              display: "flex",
              marginBottom: 0,
            }}
          >
            <InputNumber />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const getDataSource = () => {
    // function to format the data displayed in device details table
    const dataSouce = JSON.parse(JSON.stringify(data));
    return dataSouce.filter((d) => !!d.type);
  };

  return (
    <Table columns={columns} dataSource={getDataSource()} pagination={false} />
  );
};

export default SetParametersTable;
