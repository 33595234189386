import { useContext, useEffect, useState } from "react";
import {
  useDevice,
  useIntl,
  MoreButton,
  NotificationContext,
  DEACTIVATED,
} from "@datwyler/mfe-shared-components";
import { copyToClipboard } from "../../../utils";
import UpdateDeviceDialog from "../../Dialogs/UpdateDeviceDialog";
import DeactivateDeviceDialog from "../../Dialogs/DeactivateDeviceDialog";

interface MoreButtonItemsType {
  label: String;
  onClick?: Function;
  danger?: Boolean;
}

const DeviceDetailsMoreButton = (props) => {
  const { selectedDevice, tenantId, setSelectedDevice, refetchDevices } = props;
  const intl = useIntl();
  const { openNotification }: any = useContext(NotificationContext);
  const [isUpdateDeviceDialogOpen, setIsUpdateDeviceDialogOpen] =
    useState(false);
  const [isDeactivateeDeviceDialogOpen, setIsDeactivateeDeviceDialogOpen] =
    useState(false);

  const { updateDevice, updateDeviceResponseData, resetUpdateDeviceData } =
    useDevice();

  useEffect(() => {
    if (updateDeviceResponseData?.updateDevice?.device) {
      resetUpdateDeviceData();
      refetchDevices();
      if (isDeactivateeDeviceDialogOpen) {
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "success_del" }),
          placement: "topRight",
        });
        setIsDeactivateeDeviceDialogOpen(false);
        setSelectedDevice(null);
      } else {
        setSelectedDevice(updateDeviceResponseData.updateDevice.device);
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "device_updated" }),
          placement: "topRight",
        });
        setIsUpdateDeviceDialogOpen(false);
      }
    }
  }, [updateDeviceResponseData]);

  const moreButtonItems: MoreButtonItemsType[] = [
    {
      label: intl.formatMessage({ id: "copy_device_id" }),
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        copyToClipboard(selectedDevice, openNotification, intl);
      },
    },
    {
      label: intl.formatMessage({ id: "update_device" }),
      onClick: () => {
        setIsUpdateDeviceDialogOpen(true);
      },
    },
    {
      label: intl.formatMessage({ id: "delete_device" }),
      danger: true,
      onClick: () => {
        setIsDeactivateeDeviceDialogOpen(true);
      },
    },
  ];

  const handleUpdateDevice = ({ name }) => {
    const submitValues = {
      tenant: { id: tenantId },
      id: selectedDevice.id,
      name: name,
      status: selectedDevice.status,
    };

    updateDevice({
      variables: { input: submitValues },
    });
  };

  const handleDeactivateDevice = () => {
    const submitValues = {
      tenant: { id: tenantId },
      id: selectedDevice.id,
      name: selectedDevice.name,
      status: DEACTIVATED,
    };

    updateDevice({
      variables: { input: submitValues },
    });
  };

  return (
    <>
      <UpdateDeviceDialog
        selectedDevice={selectedDevice}
        handleOk={handleUpdateDevice}
        handleCancel={() => {
          setIsUpdateDeviceDialogOpen(false);
        }}
        isOpen={isUpdateDeviceDialogOpen}
      />
      <DeactivateDeviceDialog
        handleOk={handleDeactivateDevice}
        handleCancel={() => {
          setIsDeactivateeDeviceDialogOpen(false);
        }}
        isOpen={isDeactivateeDeviceDialogOpen}
      />
      <MoreButton items={moreButtonItems} />
    </>
  );
};

export default DeviceDetailsMoreButton;
