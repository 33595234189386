import { Table } from "antd";
import {
  useIntl,
  getRequiredDateFormat,
} from "@datwyler/mfe-shared-components";

const DeviceDetailsTable = (props) => {
  const { data } = props;
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: "value" }),
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "input" }),
      dataIndex: "value",
      ellipsis: true,
    },
    {
      dataIndex: "unit",
      ellipsis: true,
      width: "80px",
    },
  ];

  const getDataSource = () => {
    // function to format the data displayed in device details table
    const dataSouce = JSON.parse(JSON.stringify(data));
    return dataSouce.map((d) => {
      if (d.name === "last_connection")
        d.value = getRequiredDateFormat(
          parseInt(d.value),
          "DD-MM-YYYY HH:mm:ss"
        );
      return d;
    });
  };

  return (
    <Table columns={columns} dataSource={getDataSource()} pagination={false} />
  );
};

export default DeviceDetailsTable;
