import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "@datwyler/mfe-shared-components";
import Device from "./container/Device";
import DeviceDetails from "./container/DeviceDetails";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/device-monitoring" element={<Device />} />
      <Route
        path="/device-monitoring/deviceDetails"
        element={<DeviceDetails />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
